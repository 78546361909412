'use client';
import { staticCategories } from '@c/navigation/utils/categories';
import SportCard from './components/SportCard';
import Link from 'next/link';
import Carousel from '@ui/Carousel';

export default function ShopBySport({
  variant = 'main',
}: {
  variant: 'riding-gear' | 'parts' | 'main' | 'tires-landing-page';
}) {
  const sportCards = staticCategories
    .filter(
      (cat) =>
        cat.label !== 'Casual' &&
        cat.label !== 'Collectibles' &&
        cat.label !== 'Tires'
    )
    .map((category) => {
      let slug = '';

      if (variant === 'tires-landing-page') {
        if (category.label === 'UTV' || category.label === 'ATV') {
          slug = `/shop/utv-atv-tires`;
        } else {
          slug = `/shop/${category.label
            .toLowerCase()
            .split(' ')
            .join('-')}-tires`;
        }
      } else {
        slug = category.href;

        if (variant === 'riding-gear') {
          if (slug.endsWith('s')) slug = slug.slice(0, -1);
          slug = slug.concat('-riding-gear');
        } else if (variant === 'parts') {
          if (slug.endsWith('s')) slug = slug.slice(0, -1);
          slug = slug.concat('-parts');
        }
      }

      return (
        <div key={category.label}>
          <Link href={slug}>
            <SportCard sport={category} />
          </Link>
        </div>
      );
    });

  return (
    <Carousel
      slideClassName="max-w-[56%]"
      basis="basis-full sm:basis-1/5"
      spacing={8}
      items={sportCards}
    />
  );
}
